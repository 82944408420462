import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { AudienceTargetDetailUsage } from '@sites/data-hmm/hmm-ninja';

export const audienceTargetDetailServiceAction = createActionGroup({
  source: 'DashboardStore AudienceTargetDetailService',
  events: {
    'Load Audience Target Detail Suggestions': emptyProps(),
    'Load Audience Target Detail Suggestions Success': props<{
      targetDetailUsages: AudienceTargetDetailUsage[];
    }>(),
    'Load Audience Target Detail Suggestions Error': props<{ error: Error }>(),
  },
});
