import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { AudienceTargetDetailUsage } from '@sites/data-hmm/hmm-ninja';
import { produce } from 'immer';
import { audienceTargetDetailServiceAction } from './audience-target-detail.actions';

export const AudienceTargetDetailKey = 'audience-target-detail';

export interface AudienceTargetDetailEntityState
  extends EntityState<AudienceTargetDetailUsage> {
  error: Error | undefined;
}

export const audienceTargetDetailAdapter =
  createEntityAdapter<AudienceTargetDetailUsage>({
    selectId: (usage) => usage.targetDetail,
  });

export const initialAudienceTargetDetailState: AudienceTargetDetailEntityState =
  audienceTargetDetailAdapter.getInitialState({
    error: undefined,
  });

export const audienceTargetDetailReducer = createReducer(
  initialAudienceTargetDetailState,
  on(
    audienceTargetDetailServiceAction.loadAudienceTargetDetailSuggestionsSuccess,
    (state, { targetDetailUsages }) => {
      return audienceTargetDetailAdapter.setAll(targetDetailUsages, state);
    }
  ),
  on(
    audienceTargetDetailServiceAction.loadAudienceTargetDetailSuggestionsError,
    (state, { error }) => {
      return produce(state, (draft) => {
        draft.error = error;
      });
    }
  )
);
