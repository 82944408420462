import { createFeatureSelector } from '@ngrx/store';
import {
  audienceTargetDetailAdapter,
  AudienceTargetDetailEntityState,
  AudienceTargetDetailKey,
} from './audience-target-detail.reducer';

const selectAudienceTargetDetailState =
  createFeatureSelector<AudienceTargetDetailEntityState>(
    AudienceTargetDetailKey
  );

export const { selectAll: selectAllTargetDetailUsages } =
  audienceTargetDetailAdapter.getSelectors(selectAudienceTargetDetailState);
